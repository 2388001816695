import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import Parts from 'components/Parts/Parts';
import Tuning from 'components/Tuning/Tuning';

const App = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route element={<Parts isModalOpen={isModalOpen} setModalOpen={setModalOpen} />} path="/" />
        <Route element={<Tuning isModalOpen={isModalOpen} setModalOpen={setModalOpen} />} path="/tuning" />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
