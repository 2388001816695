import { ReactComponent as AgreementIcon } from 'assets/icons/agreement.svg';
import { ReactComponent as ApplicationIcon } from 'assets/icons/application.svg';
import { ReactComponent as CartIcon } from 'assets/icons/cart.svg';
import { ReactComponent as ContractIcon } from 'assets/icons/contract.svg';
import { ReactComponent as DeliveryIcon } from 'assets/icons/delivery.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SendLinkIcon } from 'assets/icons/sendLink.svg';
import arrow from 'assets/right-arrow.png';

const HowWeWork = () => (
  <div className="content-wrapper how-we-work">
    <div className="content">
      <h2 className="title">как мы работаем</h2>
      <hr color="#fff" width="150px" />
      <div className="schema-wrapper">
        <div className="schema-item">
          <SearchIcon className="clear" />
          <p>Находите запчасти в любом магазине</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <SendLinkIcon className="clear" />
          <p>Скидываете ссылку на товар нам</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <ContractIcon />
          <p>
            Заключаем
            <br />
            {' '}
            договор
          </p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <PaymentIcon />
          <p>Вносите предоплату</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <CartIcon className="clear" />
          <p>Мы заказываем товар по ссылке</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <DeliveryIcon />
          <p>Доставляем в Минск</p>
        </div>
      </div>
      <h2 className="title how-we-work-second-title">или</h2>
      <hr color="#fff" width="150px" />
      <div className="schema-wrapper">
        <div className="schema-item">
          <ApplicationIcon />
          <p>Подаёте заявку</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>

        <div className="schema-item">
          <SearchIcon className="clear" />
          <p>Мы подбираем товар</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <AgreementIcon />
          <p>Согласовываем с вами товар</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <ContractIcon />
          <p>
            Договор и предоплата
          </p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <CartIcon className="clear" />
          <p>Мы заказываем товар по ссылке</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <DeliveryIcon />
          <p>Доставляем в Минск</p>
        </div>
      </div>
    </div>
  </div>
);

export default HowWeWork;
