import { ReactComponent as DetailingIcon } from 'assets/icons/detailing.svg';
import { ReactComponent as NoscatIcon } from 'assets/icons/noscat.svg';
import { ReactComponent as OriginIcon } from 'assets/icons/origins.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { ReactComponent as RepairIcon } from 'assets/icons/repair.svg';
import { ReactComponent as TapeIcon } from 'assets/icons/tape.svg';
import { ReactComponent as WarrantyIcon } from 'assets/icons/warranty.svg';
import { ReactComponent as WashIcon } from 'assets/icons/wash.svg';
import arrow from 'assets/right-arrow.png';

const TuningAdvantages = () => (
  <div className="content-wrapper how-we-work tuning-advantages">
    <div className="content">
      <h2 className="title">как мы работаем</h2>
      <hr color="#fff" width="150px" />
      <div className="schema-wrapper">
        <div className="schema-item">
          <OriginIcon />
          <p>
            Оригинал или
            <br />
            {' '}
            аналог
          </p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <WarrantyIcon />
          <p>Установка под ключ с гаранитией</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <RepairIcon />
          <p>
            Подгон, подготовка, покраска, установка
          </p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <PaymentIcon />
          <p>Наличный/безналичный расчёт</p>
        </div>
      </div>
      <h2 className="title how-we-work-second-title">Дополнительные услуги</h2>
      <hr color="#fff" width="150px" />
      <div className="schema-wrapper">
        <div className="schema-item">
          <TapeIcon />
          <p>Оклейка плёнкой и тонировка</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>

        <div className="schema-item">
          <WashIcon />
          <p>Защитные покрытия и полировка</p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <DetailingIcon />
          <p>
            Детейлинг
            <br />
            {' '}
            химчистка
          </p>
        </div>
        <div>
          <img alt="" className="schema-arrow" src={arrow} />
        </div>
        <div className="schema-item">
          <NoscatIcon />
          <p>Подпор ноускатов с возможностью замены</p>
        </div>
      </div>
    </div>
  </div>
);

export default TuningAdvantages;
