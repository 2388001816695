import Contacts from 'components/Contacts/Contacts';
import Gallery from 'components/Gallery/Gallery';
import IntroTuning from 'components/IntroTuning/Intro';
import TuningAdvantages from 'components/TuningAdvantages/TuningAdvantages';

const Tuning = ({ isModalOpen, setModalOpen }) => (
  <>
    <IntroTuning isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    <TuningAdvantages />
    <Gallery />
    <Contacts />
  </>
);

export default Tuning;
