import Advantages from 'components/Advantages/Advantages';
import Contacts from 'components/Contacts/Contacts';
import HowWeWork from 'components/HowWeWork/HowWeWork';
import Intro from 'components/Intro/Intro';

const Parts = ({ isModalOpen, setModalOpen }) => (
  <>
    <Intro isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    <HowWeWork />
    <Advantages setModalOpen={setModalOpen} />
    <Contacts />
  </>
);

export default Parts;
