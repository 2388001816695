import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { sendMessage } from 'api/api';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-icon.svg';

import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';

const phoneNumberMask = [
  '+',
  '3',
  '7',
  '5',
  ' ',
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const maxNameLength = 20;
const maxMessageLength = 500;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(maxNameLength, 'Имя не должно превышать 20 символов')
    .required('Обязательное поле'),
  phone: Yup.string()
    .matches(/\+375 \(\d{2}\) \d{3}-\d{2}-\d{2}/, 'Неверный формат номера телефона')
    .required('Обязательное поле'),
  message: Yup.string()
    .max(maxMessageLength, 'Сообщение не должно превышать 500 символов')
    .matches(/[\s\S]*/g, 'Только печатаемые символы клавиатуры'),
});

const Modal = ({ isOpen, onClose }) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  if (!isOpen) {
    return null;
  }

  const handleSubmit = async (values) => {
    try {
      await sendMessage(values);
      setSubmitMessage('Заявка отправлена, мы свяжемся с вами в ближайшее время!');
    } catch (e) {
      setSubmitMessage('Произошла ошибка, попробуйте позвонить нам.');
    } finally {
      setIsSubmitSuccess(true);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-body">
        <button
          className="modal-close-button"
          onClick={onClose}
        >
          X
        </button>
        {isSubmitSuccess && (
          <div className="success-message-wrapper">
            <SuccessIcon />
            <p>{submitMessage}</p>
          </div>
        )}
        {!isSubmitSuccess && (
          <Formik
            initialValues={{ name: '', phone: '', message: '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {() => (
              <Form>
                <div className="form-control">
                  <label htmlFor="name">Имя:</label>
                  <Field name="name" type="text" />
                  <ErrorMessage name="name" render={(errorMessage) => (<span className="form-error">{errorMessage}</span>)} />
                </div>
                <div className="form-control">
                  <label htmlFor="phone">Номер телефона:</label>
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <MaskedInput {...field} guide mask={phoneNumberMask} />
                    )}
                  />
                  <ErrorMessage name="phone" render={(errorMessage) => (<span className="form-error">{errorMessage}</span>)} />
                </div>
                <div className="form-control">
                  <label htmlFor="message">Сообщение:</label>
                  <Field as="textarea" name="message" rows="4" />
                  <ErrorMessage name="message" render={(errorMessage) => (<span className="form-error">{errorMessage}</span>)} />
                </div>
                <button className="modal-button" type="submit">Отправить</button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default Modal;
