import { NavLink } from 'react-router-dom';
import { ReactComponent as Clock } from 'assets/clock.svg';
import logo2 from 'assets/logo-2.png';
import phone from 'assets/phone.png';
import { ReactComponent as Telegram } from 'assets/telegram.svg';

const Header = () => (
  <header className="header">
    <div className="content">
      <img alt="ИКАЭС Трейд" className="logo" src={logo2} />
      <div className="contacts-info">
        <div className="working-time info">
          <Clock />
          Пн. - Пт.: 9.00-18.00
        </div>

        <div className="header-contacts">
          <a className="tel info" href="tel:+375333020744">
            <img alt="телефон" className="header-icon" src={phone} />
            +375 (33) 302-07-44
          </a>
          <a className="social info" href="https://t.me/IKS_Trade" rel="noreferrer" target="_blank">
            <Telegram />
            @IKASTrade
          </a>
        </div>
      </div>
    </div>
    <div className="content">
      <ul className="navigation-container">
        <li className="navigation-item"><NavLink to="/">Автозапчасти</NavLink></li>
        <li className="navigation-item"><NavLink to="/tuning">Тюнинг</NavLink></li>
      </ul>
    </div>
  </header>
);

export default Header;
