import Modal from 'components/Modal/Modal';

const IntroTuning = ({ setModalOpen, isModalOpen }) => (
  <div className="content-wrapper intro-tuning">
    <div className="backdrop">
      <div className="content">
        <h2 className="title">
          Индивидуальный подход.
          <br />
          <br />
          Подберём и привезём готовые обвесы для вашего авто из Европы, Китая, ОАЭ
        </h2>

        <button className="cta-button" onClick={() => setModalOpen(true)}>подробнее</button>
      </div>
    </div>

    {isModalOpen && <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />}
  </div>
);

export default IntroTuning;
