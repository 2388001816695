import { ReactComponent as Clock } from 'assets/clock.svg';
import { ReactComponent as Location } from 'assets/location.svg';
import phone from 'assets/phone.png';
import { ReactComponent as Telegram } from 'assets/telegram.svg';

const Contacts = () => {
  // eslint-disable-next-line max-len
  const mapUrl = 'https://yandex.ru/map-widget/v1/?um=constructor%3A2904eebeb8655ea58605c47d487b9c22ddd99719593c3f13c96d30823d4b5d53&amp;source=constructor';

  return (
    <div className="content-wrapper contacts">
      <div className="content">
        <h2 className="title">контакты</h2>
        <hr color="#fff" width="150px" />

        <div className="contacts-wrapper">
          <div className="contacts-info-wrapper">
            <ul className="contacts-list">
              <li>
                <Location />
                {' '}
                <span>г. Минск, пр-т Независимости 199, пом. 1</span>
              </li>
              <li>
                <Clock />
                Пн. - Пт.: 9.00-18.00
              </li>
              <li>
                <a className="tel info" href="tel:+375333020744">
                  <img alt="телефон" className="header-icon" src={phone} />
                  +375 (33) 302-07-44
                </a>
              </li>
              <li>
                <a className="social info" href="https://t.me/IKS_Trade" rel="noreferrer" target="_blank">
                  <Telegram />
                  @IKASTrade
                </a>
              </li>
            </ul>

            <h3 className="requisites-title">Наши реквизиты</h3>
            <ul className="contacts-list requisites-list">
              <li>
                ООО &quot;ИКАЭС Трейд&quot;
              </li>
              <li>
                Юридический адрес:
                &nbsp;
                <span>г. Минск, пр-т Независимости 199, пом. 1</span>
              </li>
              <li>
                <a className="tel info" href="tel:+375333020744">
                  Телефон:
                  &nbsp;
                  <span>+375 (33) 302-07-44</span>
                </a>
              </li>
              <li>
                Почтовый адрес:
                &nbsp;
                <span>220056б г. Минск, а/я 39</span>
              </li>
              <li>
                УНП:
                &nbsp;
                <span>193719216</span>
              </li>
            </ul>
          </div>

          <div className="contacts-map">
            <iframe
              frameBorder="0"
              height="400"
              src={mapUrl}
              title="map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
