const Footer = () => (
  <footer className="footer">
    <div className="content">
      &copy; IKASTrade
      {' '}
      {new Date().getFullYear()}
    </div>
  </footer>
);

export default Footer;
