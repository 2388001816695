const Advantages = ({ setModalOpen }) => (
  <div className="content-wrapper advantages">
    <div className="content">
      <h2 className="title">наши приемущества</h2>
      <hr color="#fff" width="150px" />
      <div className="schema-wrapper">
        <div className="schema-item">
          <p>качественный подбор</p>
        </div>

        <div className="schema-item">
          <p>быстрая доставка 1-2 недели</p>
        </div>

        <div className="schema-item">
          <p>документы на приобретённые запчасти</p>
        </div>

        <div className="schema-item">
          <p>наличный и безналичный расчёт</p>
        </div>

        <div className="schema-item">
          <p>Система лояльности для корпоративных клиентов</p>
        </div>
      </div>
    </div>

    <div className="advantages-button-wrapper">
      <button className="cta-button" onClick={() => setModalOpen(true)}>сделать заказ</button>
    </div>
  </div>
);

export default Advantages;
