import Modal from 'components/Modal/Modal';

const Intro = ({ setModalOpen, isModalOpen }) => (
  <div className="content-wrapper intro">
    <div className="backdrop">
      <div className="content">
        <h2 className="title">
          Подбор и привоз запчастей для легковых и грузовых автомобилей из Европы
        </h2>

        <button className="cta-button" onClick={() => setModalOpen(true)}>сделать заказ</button>
      </div>
    </div>

    {isModalOpen && <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />}
  </div>
);

export default Intro;
