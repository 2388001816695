import React, { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import car1 from 'assets/gallery/car_1.jpg';
import car2 from 'assets/gallery/car_2.jpg';
import car3 from 'assets/gallery/car_3.jpg';
import car4 from 'assets/gallery/car_4.jpg';
import car5 from 'assets/gallery/car_5.jpg';
import car6 from 'assets/gallery/car_6.jpg';
import car7 from 'assets/gallery/car_7.jpg';
import car8 from 'assets/gallery/car_8.jpg';
import car9 from 'assets/gallery/car_9.jpg';
import car10 from 'assets/gallery/car_10.jpg';
import car11 from 'assets/gallery/car_11.jpg';
import car12 from 'assets/gallery/car_12.jpg';
import car13 from 'assets/gallery/car_13.jpg';
import car14 from 'assets/gallery/car_14.jpg';
import car15 from 'assets/gallery/car_15.jpg';
import car16 from 'assets/gallery/car_16.jpg';
import car17 from 'assets/gallery/car_17.jpg';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    name: 'car1',
    original: car1,
    thumbnail: car1,
  },
  {
    name: 'car2',
    original: car2,
    thumbnail: car2,
  },
  {
    name: 'car3',
    original: car3,
    thumbnail: car3,
  },
  {
    name: 'car4',
    original: car4,
    thumbnail: car4,
  },
  {
    name: 'car5',
    original: car5,
    thumbnail: car5,
  },
  {
    name: 'car6',
    original: car6,
    thumbnail: car6,
  },
  {
    name: 'car7',
    original: car7,
    thumbnail: car7,
  },
  {
    name: 'car8',
    original: car8,
    thumbnail: car8,
  },
  {
    name: 'car9',
    original: car9,
    thumbnail: car9,
  },
  {
    name: 'car10',
    original: car10,
    thumbnail: car10,
  },
  {
    name: 'car11',
    original: car11,
    thumbnail: car11,
  },
  {
    name: 'car12',
    original: car12,
    thumbnail: car12,
  },
  {
    name: 'car13',
    original: car13,
    thumbnail: car13,
  },
  {
    name: 'car14',
    original: car14,
    thumbnail: car14,
  },
  {
    name: 'car15',
    original: car15,
    thumbnail: car15,
  },
  {
    name: 'car16',
    original: car16,
    thumbnail: car16,
  },
  {
    name: 'car17',
    original: car17,
    thumbnail: car17,
  },
];

const Gallery = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsSliderOpen(true);
  };

  const handleClickOutside = (event) => {
    if (sliderRef.current && !sliderRef.current.contains(event.target)) {
      setIsSliderOpen(false);
    }
  };

  useEffect(() => {
    if (isSliderOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSliderOpen]);

  return (
    <div className="advantages">
      <div className="content">
        <h2 className="title">наши работы</h2>
        <hr color="#fff" width="150px" />
        {isSliderOpen ? (
          <div ref={sliderRef} className="slider-wrapper">
            <button className="close-button" onClick={() => setIsSliderOpen(false)}>
              Х
            </button>
            <ImageGallery
              items={images}
              onClose={() => setIsSliderOpen(false)}
              onScreenChange={(fullScreen) => {
                if (!fullScreen) {
                  setIsSliderOpen(false);
                }
              }}
              showThumbnails={false}
              startIndex={currentIndex}
            />
          </div>
        ) : (
          <div className="image-grid">
            {images.map((image, index) => (
              <div
                key={image.name}
                className="image-item"
                onClick={() => handleImageClick(index)}
                style={{ backgroundImage: `url(${image.original})` }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
